// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  height: 100dvh;
  width: 100dvw;
}

:host.menubar-top {
  flex-direction: column;
}
:host.menubar-top .menubar {
  flex-direction: row;
  justify-content: flex-end;
}

:host.menubar-left {
  flex-direction: row;
}
:host.menubar-left .menubar {
  flex-direction: column;
  justify-content: flex-start;
}

.menubar {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.2666666667);
  display: flex;
  float: none;
  margin: 0;
  padding: 4px;
}

gridster {
  background: transparent;
  overflow: hidden !important;
  flex-grow: 1;
}

gridster-item {
  border: 1px solid silver;
}
gridster-item .close-button {
  display: none;
}
gridster-item:hover .close-button {
  display: block;
}
gridster-item .titlebar {
  display: none;
}
gridster-item:hover .titlebar {
  display: block;
}

gridster-item-content {
  overflow: auto;
  overflow-x: hidden;
}

app-chat-widget {
  display: block;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/components/strategy-grid/grid/grid.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,cAAA;EACA,aAAA;AACF;;AAEA;EACE,sBAAA;AACF;AACE;EACE,mBAAA;EACA,yBAAA;AACJ;;AAGA;EACE,mBAAA;AAAF;AAEE;EACE,sBAAA;EACA,2BAAA;AAAJ;;AAIA;EACE,mBAAA;EACA,6CAAA;EACA,aAAA;EACA,WAAA;EACA,SAAA;EACA,YAAA;AADF;;AAIA;EACE,uBAAA;EACA,2BAAA;EACA,YAAA;AADF;;AAIA;EACE,wBAAA;AADF;AAGE;EACE,aAAA;AADJ;AAIE;EACE,cAAA;AAFJ;AAKE;EACE,aAAA;AAHJ;AAME;EACE,cAAA;AAJJ;;AAQA;EACE,cAAA;EACA,kBAAA;AALF;;AAQA;EACE,cAAA;EACA,YAAA;AALF","sourcesContent":[":host {\n  display: flex;\n  height: 100dvh;\n  width: 100dvw;\n}\n\n:host.menubar-top {\n  flex-direction: column;\n\n  .menubar {\n    flex-direction: row;\n    justify-content: flex-end;\n  }\n}\n\n:host.menubar-left {\n  flex-direction: row;\n\n  .menubar {\n    flex-direction: column;\n    justify-content: flex-start;\n  }\n}\n\n.menubar {\n  align-items: center;\n  background-color: #0004;\n  display: flex;\n  float: none;\n  margin: 0;\n  padding: 4px;\n}\n\ngridster {\n  background: transparent;\n  overflow: hidden !important;\n  flex-grow: 1;\n}\n\ngridster-item {\n  border: 1px solid silver;\n\n  & .close-button {\n    display: none;\n  }\n\n  &:hover .close-button {\n    display: block;\n  }\n\n  & .titlebar {\n    display: none;\n  }\n\n  &:hover .titlebar {\n    display: block;\n  }\n}\n\ngridster-item-content {\n  overflow: auto;\n  overflow-x: hidden;\n}\n\napp-chat-widget {\n  display: block;\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
