export interface Subscribable<T> {
  subscribe(handler: (data?: T) => void): Unsubscribable;
}

export interface Unsubscribable {
  unsubscribe(): void;
}

export interface EventHandler<T> {
  subscribe(handler: (data?: T) => void): void;
  unsubscribe(handler: (data?: T) => void): void;
}

export class SubscriptionContainer {
  #subs: Unsubscribable[] = [];

  public set add(s: Unsubscribable) {
    this.#subs.push(s);
  }

  public dispose(): void {
    this.#subs.forEach((s) => s?.unsubscribe());
    this.#subs = [];
  }
}

export const wrapEventHandler = <T>(dispatcher: EventHandler<T>): Subscribable<T> => ({
  subscribe: (handler: (data?: T) => void) => {
    dispatcher.subscribe(handler);
    return { unsubscribe: () => dispatcher.unsubscribe(handler) };
  }
});
